// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-jsx": () => import("/Users/minkeunkim/dev/repo/mimosa-web/src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-contact-jsx": () => import("/Users/minkeunkim/dev/repo/mimosa-web/src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-index-jsx": () => import("/Users/minkeunkim/dev/repo/mimosa-web/src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-m-3-jsx": () => import("/Users/minkeunkim/dev/repo/mimosa-web/src/pages/m3.jsx" /* webpackChunkName: "component---src-pages-m-3-jsx" */),
  "component---src-pages-pricing-jsx": () => import("/Users/minkeunkim/dev/repo/mimosa-web/src/pages/pricing.jsx" /* webpackChunkName: "component---src-pages-pricing-jsx" */),
  "component---src-pages-website-jsx": () => import("/Users/minkeunkim/dev/repo/mimosa-web/src/pages/website.jsx" /* webpackChunkName: "component---src-pages-website-jsx" */)
}

